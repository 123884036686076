<template>
    <div class="left-bar">
        <div class="logo">
            <router-link v-if = "!isIndexPage" class= "l_index" to="/userCenter">首页</router-link>
            <router-link to="/">
                <img :src="require('@/assets/images/education/logo_bottom.png')" alt="">
            </router-link>
        </div>
        <div class="side-bar" v-show = "screenWidth <= 750 ? menuOn : true">
            <div class = "menuHeader">
                <a v-show = "screenWidth <= 750" @click = "logout" class = "m_btn" href = "javascript:void(0);">退出登录</a>
            </div>
            <div class="person">
                <div class="role">
                    <p>个人功能</p>
                </div>
                <div class="navbar-list" @click = "menuToggle">
                    <router-link :to="item.url" v-for="(item, index) in navList[0]" :key="index" :class="{'actived': index === activedIdx}">{{ item.name }}</router-link>
                </div>
            </div>
            <div class="teacher" v-if="roles === 'teacher'">
                <div class="role">
                    <p>老师功能</p>
                </div>
                <div class="navbar-list" @click = "menuToggle">
                    <router-link :to="item.url" v-for="(item, index) in navList[1]" :key="index" :class="{'actived': index === activedIdx}">{{ item.name }}</router-link>
                </div>
            </div>
        </div>

        <a @click = "menuToggle" class = "side-btn" :class = "menuOn ? 'on' : ''" href = "javascript:void(0);">
            <i></i>
            <i></i>
            <i></i>
        </a>
    </div>
</template>

<script>
    import {urls, banbaoUrl} from '@/lib/common';
    import Cookies from "js-cookie";
    export default {
        name: "leftbarComponent",
        props: {
            routerUrl: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                banbaoUrl,
                personNavs: [
                    [
                        {
                            name: '个人资料',
                            url: '/personalInfo'
                        }, {
                            name: '我的课程',
                            url: '/myCourses'
                        }, {
                            name: '我的订单',
                            url: '/myOrders'
                        }, {
                            name: '我的作品',
                            url: '/myCreation'
                        },
                        // {
                        //     name: '宝宝资料',
                        //     url: '/babyInfo'
                        // }
                    ]
                ],
                teacherNavs: [
                    [
                        {
                            name: '个人资料',
                            url: '/personalInfo'
                        },
                        // {
                        //     name: '宝宝资料',
                        //     url: '/babyInfo'
                        // }
                    ], [
                        {
                            name: '课程管理',
                            url: '/courseManagement'
                        }, {
                            name: '课件资源管理',
                            url: '/coursewareManagement'
                        }, {
                            name: '学生管理',
                            url: '/studentManagement'
                        }, {
                            name: '学生订单',
                            url: '/studentOrders'
                        }, {
                            name: '我的作品',
                            url: '/myCreation'
                        }
                    ]
                ],
                navList: [],
                activedIdx: 0,
                roles: '',
                screenWidth:document.body.clientWidth,
                menuOn:document.body.clientWidth <= 750 ? false : true,
                isIndexPage:location.href.indexOf('/userCenter') > -1 ? true : false,
            }
        },
        methods: {
            logout(){
                const url = urls.logout;
                this.$cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});

                this.$cookies.remove('app.token', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.username', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.nickname', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.email', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.uid', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.isTeacher', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.roles', {domain: banbaoUrl, path: ''});
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userinfo');
                this.$http.post(url).then(res => {
                    console.log(res)
                    if (res.data.status !== 200) {
                        this.$message.error(res.data.message);
                    } else {
                        this.menuToggle();
                        this.$message.success("已退出登录.");
                        setTimeout(() => {
                            
                            this.$router.replace({
                                path: "/login"
                            })
                        }, 2000)
                    }
                })
            },
            menuToggle(){
                this.menuOn = !this.menuOn;
                if(this.menuOn){
                    document.body.style.overflow = 'hidden';
                }
                else{
                    document.body.style.overflow = '';
                }
            }
        },
        created() {
            const roles = sessionStorage.getItem('roles') || Cookies.get('app.roles', {domain: banbaoUrl, path: ''});
            this.roles = roles;
            if (this.roles === 'teacher') {
                this.navList = this.teacherNavs;
            } else if (this.roles === 'student') {
                this.navList = this.personNavs;
            } else {
                this.navList = [
                    [
                        {
                            name: '个人资料',
                            url: '/personalInfo'
                        }
                    ]
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
    .left-bar {
        width: 16%;
        min-width: 240px;
        max-width: 300px;
        min-height: 100vh;
        overflow-y: auto;
        background: url("../../../assets/images/user-center/nav_bg.png") no-repeat center center;
        background-size: 100% 100%;
        .logo {
            padding: 0.6vw 15%;
            height: 4vw;
            text-align: center;
            box-sizing: border-box;
            img {
                width: auto;
                height: 100%;
            }
            .l_index{
                display: none;
            }
        }
        .side-bar {
            .role {
                font-size: 0.93vw;
                color: #fff;
                padding-left: 15%;
                background: #02A5E6;
                line-height: 2.6vw;
                height: 2.6vw;
            }
            .navbar-list {
                a {
                    font-size: 0.83vw;
                    display: block;
                    color: #fff;
                    padding-left: 15%;
                    line-height: 2.6vw;
                    height: 2.6vw;
                    &.router-link-exact-active {
                        background-color: #51D7FA;
                        position: relative;
                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            left: 11%;
                            top: 50%;
                            margin-top: -6px;
                            border: 6px solid transparent;
                            border-left-color: white;
                        }
                    }
                }
            }
        }
        .side-btn{
            display: none;
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        .left-bar{
            width: 100%;
            max-width: none;
            min-height: auto;
            .fontFn(240,height);
            background:url('../../../assets/images/phone/center_index_bg.png') no-repeat center top;
            background-size: 100% auto;
            .logo{
                text-align: left;
                padding: 3.2% 16px;
                height: 80px;
                img{
                    .fontFn(150,width);
                    height: auto;
                }
                a{
                    display: inline-block;
                    vertical-align: top;
                }
                .l_index{
                    display: inline-block;
                    vertical-align: top;
                    color: #fff;
                    .fontFn(16);
                    line-height: 60px;
                    .fontFn(60,padding-right);
                }
            }
            .side-bar{
                overflow: auto;
                position: fixed;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:rgba(0,0,0,0.85);
                z-index: 1000100;
                .menuHeader{
                  .fontFn(60,height);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .m_btn{
                    color:#fff;
                    .fontFn(16);
                    padding: 2%;
                  }
                }
                .role{
                    height: auto;
                    line-height: normal;
                    background: none;
                    padding-left: 0;
                    p{
                        border-top:1px solid #5d5d5d;
                        color:#fff;
                        display: block;
                        padding-left: 4%;
                        .fontFn(16);
                        .fontFn(40,height);
                        .fontFn(40,line-height);
                    }
                }
                .navbar-list{
                    a{
                        border-top:1px solid #5d5d5d;
                        color:#fff;
                        padding-left: 11%;
                        display: block;
                        .fontFn(16);
                        .fontFn(40,height);
                        .fontFn(40,line-height);
                        &.router-link-exact-active{
                            background: none;
                            &::before{
                                content:none;
                            }
                        }
                    }
                }
            }
            .side-btn{
                display: block;
                z-index: 1000101;
                position: absolute;
                padding:3% 4%;
                padding-top: 6%;
                top:0;
                right:0;
                i{
                    border-radius: 4px;
                    display: block;
                    width: 18px;
                    height: 3px;
                    background-color: rgba(255,255,255,0.8);
                    margin-bottom: 4px;
                    transition-duration: 0.3s;
                }
                &.on{position: fixed;}
                &.on i:nth-child(1){
                    transform:rotate(45deg) translate(12.5%,160%);
                    -webkit-transform:rotate(45deg) translate(12.5%,160%);
                    -moz-transform:rotate(45deg) translate(12.5%,160%);
                    -ms-transform:rotate(45deg) translate(12.5%,160%);
                    -o-transform:rotate(45deg) translate(12.5%,160%);
                    margin:0;
                }
                &.on i:nth-child(2){
                    opacity: 0;
                }
                &.on i:nth-child(3){
                    transform:rotate(-45deg) translate(12.5%,-160%);
                    -webkit-transform:rotate(-45deg) translate(12.5%,-160%);
                    -moz-transform:rotate(-45deg) translate(12.5%,-160%);
                    -ms-transform:rotate(-45deg) translate(12.5%,-160%);
                    -o-transform:rotate(-45deg) translate(12.5%,-160%);
                    margin:0;
                }
            }
        }
    }
</style>