<template>
    <div class="topbar">
        <v-breadcrumbs :items="items" divider=">" class="breadcrumbs"></v-breadcrumbs>
        <div :class="['tips', tipsType === 1 ? 'primary' : 'fail', showTips ? 'show-tips' : '']">
            <i :class="['icon', tipsType === 1 ? 'icon-primary' : 'icon-fail']">{{ tipsType === 1 ? 'i' : '&times;'}}</i>
            <span>{{ tipsText }}</span>
        </div>
        <div class="rf">
            <router-link to="/education?n=OTA%3D">
                <i class="icon icon-education"></i>
                邦宝教育
            </router-link>
            <router-link to="/">
                <i class="icon icon-home"></i>
                返回主站
            </router-link>
        </div>
        <div class="user">
            <i class="icon-user"></i>
            <span v-if="token">{{ username || '用户名' }}</span>
            <ul class="editor-list" v-if="token">
                <!--<li>
                    <p>修改密码</p>
                </li>
                <li>
                    <p>找回密码</p>
                </li>-->
                <li>
                    <p @click="dialogVisible = true">退出</p>
                </li>
            </ul>
        </div>
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="30%"
                :before-close="handleClose">
            <span>确定要退出登录？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="logout">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {urls, banbaoUrl} from '@/lib/common';
    export default {
        name: "topBarComponent",
        props: {
            items: {
                type: Array,
                default: function() {
                    return []
                }
            },
            tipsText: {
               type: String,
               default: ''
            },
            tipsType: {
               type: Number,
               default: 0
            },
            showTips: {
               type: Boolean,
               default: false
            },
        },
        data() {
            return {
                banbaoUrl,
                username: '',
                token: '',
                password: '',
                code: '',
                uuid: '',

                userContact: '', // 真实姓名
                userMail: '', // 邮箱
                userPhone: '', // 手机号码
                userPass: '', // 注册密码
                phoneCode: '', // 手机验证码
                valid: false,
                imgCode: null,
                editorType: '',
                banned: false,
                count: 60,
                dialogVisible: false
            }
        },
        methods: {
            logout() { // 退出登录
                const url = urls.logout;
                this.$cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});

                this.$cookies.remove('app.token', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.username', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.nickname', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.email', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.uid', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.isTeacher', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.roles', {domain: banbaoUrl, path: ''});
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userinfo');
                this.$http.post(url).then(res => {
                    console.log(res)
                    if (res.data.status !== 200) {
                        this.$message.error(res.data.message);
                    } else {
                        this.$message.success("已退出登录.");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login"
                            })
                        }, 2000)
                    }
                })
            },
            handleClose() {
                this.dialogVisible = false
            }
        },
        created() {
            const token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''}) || sessionStorage.getItem('token');
            const userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
            // const nickname = this.$cookies.get('app.nickname', {domain: banbaoUrl, path: ''});
            if (token) {
                this.token = token
            } else {
                this.$message.warning("请登录!");
                setTimeout(() => {
                    this.$router.push({
                        path: '/login'
                    })
                }, 2000)
            }
            if (userinfo) {
                this.username = userinfo.realName;
            }
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4vw;
        padding: 0 2vw 0 1vw;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        .tips {
            padding: 0.5vw 2vw;
            border-radius: 6px;
            font-size: 0.83vw;
            border: 1px solid transparent;
            transform: translateY(-5vw);
            transition: all .3s linear;
            &.primary {
                border-color: #ACDFFF;
                background-color: #E6F7FF;
            }
            &.fail {
                border-color: #FFA6A1;
                background-color: #FFF1F0;
            }
            &.show-tips {
                transform: translateY(0);
            }
            .icon {
                display: inline-block;
                width: 1vw;
                height: 1vw;
                line-height: 1vw;
                border-radius: 50%;
                background: #ccc;
                color: #fff;
                text-align: center;
                margin-right: 5px;
                &.icon-primary {
                    background-color: #108EE9;
                }
                &.icon-fail {
                    font-size: 1vw;
                    line-height: 0.8vw;
                    background-color: #F5222D;
                }
            }
        }
        >.rf {
            text-align: right;
            box-sizing: border-box;
            margin-left: 40%;
            line-height: 200%;
            a {
                display: inline-block;
                margin-left: 2vw;
                color: #02A5E6;
                font-size: 16px;
                &:last-child {
                    color: #D93C45;
                }
                .icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: url("../../../assets/images/icon_education.png") no-repeat center center;
                    background-size: 100%;
                    position: relative;
                    top: 4px;
                }
                .icon-home {
                    background-image: url("../../../assets/images/icon_home.png");
                }
            }
        }
        .user {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.83vw;
            cursor: pointer;
            position: relative;
            line-height: 4vw;
            
            .icon-user {
                display: inline-block;
                width: 1.8vw;
                height: 1.8vw;
                background: url("../../../assets/images/user-center/icon_user.png") no-repeat center center;
                background-size: 100%;
                margin-right: 1vw;
            }
            .v-btn {
                font-size: 0.83vw;
                margin-right: 10px;
                background-color: #FB8C00 !important;
                &.v-size--x-small {
                    padding: 5px 15px;
                    height: auto;
                    line-height: normal;
                }
                &.primary {
                    background-color: #24CEFF !important;
                }
            }
            &:hover {
                .editor-list {
                    display: block;
                }
            }
            .editor-list {
                display: none;
                position: absolute;
                top: 4vw;
                background: #fff;
                box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.2);
                line-height: normal;
                li {
                    text-align: center;
                    width: 100px;
                    height: 3vw;
                    line-height: 3vw;
                    overflow: hidden;
                    font-size: 0.83vw;
                    border-bottom: 1px solid #eaeaea;
                    position: relative;
                    &:last-child {
                        border-bottom: 0;
                    }
                    p {
                        position: absolute;
                        left: 50%;
                        margin-left: -50px;
                        right: 0;
                        z-index: 99;
                    }
                    &::before {
                        transform: translateX(105%);
                        transition: all .2s linear;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100px;
                        height: 100%;
                        background-color: #51D7FA;
                    }
                    &:hover {
                        transition: all .2s linear;
                        color: #fff;
                        &::before {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
        @{deep} .v-overlay__content {
            width: 25%;
            .login-box {
                background: #fff;
                border-radius: 10px;
                color: #666;
                h2 {
                    text-align: center;
                    font-size: 1vw;
                    padding: 10px;
                    border-bottom: 1px solid #ddd;
                    border-radius: 10px 10px 0 0;
                    font-weight: normal;
                    background-color: #ededed;
                    color: #333;
                    position: relative;
                    .icon-close {
                        position: absolute;
                        top: 5px;
                        right: 10px;
                        font-size: 1.5vw;
                        cursor: pointer;
                        font-weight: normal;
                    }
                }
                .login-form,
                .register-form {
                    >div {
                        line-height: 46px;
                        font-size: 14px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        flex-wrap: nowrap;
                        border-bottom: 1px solid #eaeaea;
                        &:last-child {
                            border-bottom: 0;
                        }
                        label {
                            width: 25%;
                            text-indent: 1em;
                        }
                        input {
                            color: #333;
                            margin-left: 10px;
                        }
                        &.phone {
                            label {
                                width: 30%;
                            }
                        }
                        &.code,
                        &.phone-code {
                            display: block;
                            label {
                                float: left;
                                width: 25%;
                                text-indent: 1em;
                            }
                            input {
                                float: left;
                                width: 38%;
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }

                        }
                        &.code {
                            img {
                                float: right;
                                width: 25%;
                                height: 46px;
                                cursor: pointer;
                            }
                        }
                        &.phone-code {
                            display: block;
                            button {
                                float: right;
                                background: #FB8C00;
                                color: #fff;
                                padding: 0 15px;
                                &:disabled {
                                    background: #aaa;
                                }
                            }
                        }
                    }
                    .btn {
                        button {
                            font-size: 16px;
                            width: 100%;
                            text-align: center;
                            background-color: #24CEFF;
                            color: #fff;
                            border-radius: 0 0 0 10px;
                            &.btn-register {
                                border-radius: 0 0 10px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>